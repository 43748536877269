import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/camera',
  //   redirect: ''
  // },
  // {
  //   path: '/images',
  //   redirect: ''
  // },
  {
    path: '/',
    component: TabsPage,
    children: [
      // {
      //   path: '/',
      //   redirect: '/camera'
      // },
      {
        path: '',
        component: () => import('@/views/Camera.vue')
      },
      {
        path: '/images',
        component: () => import('@/views/Images.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
